
    import { Link } from 'react-router-dom'

    // SVG + IMG
    import phone from "../../../Assets/Svg/phone.svg"
    import email from "../../../Assets/Svg/email.svg"
    import location from "../../../Assets/Svg/location.svg"
    import logo from "../../../Assets/Img/logo3.png"

    import style from "./HeaderContact.module.css"
    import { useTranslation } from 'react-i18next'

    const { HeaderStyle, iconsStyle, logoStyle, textStyle } = style
    const HeaderContact = () => {

        const { t } = useTranslation()
        return (
            <div>
                <div className={`${HeaderStyle} d-flex justify-content-evenly align-items-center w-100`}>
                    <div className={`${logoStyle}`}>
                        <Link to={"/"}><img src={logo} className='w-100' alt="" /></Link>
                    </div>
                    <div className={` d-flex align-items-center gap-2`}>
                        <a href="tel:0533565303" className='d-flex align-items-center gap-2 text-decoration-none'>
                            <img src={phone} className={`${iconsStyle}`} alt="" />
                            <p className={`${textStyle} text-white mb-0`}>0533565303</p>
                        </a>
                    </div>
                    <div className={` d-flex align-items-center gap-2`}>
                        <a href="mailto:Elsolbalzahaby@gmail.com" className='d-flex align-items-center gap-2 text-decoration-none'>
                            <img src={email} className={`${iconsStyle}`} alt="" />
                            <p className={`${textStyle} text-white mb-0`}>
                                Elsolbalzahaby@gmail.com</p>
                        </a>
                    </div>
                    <div className={` d-flex align-items-center gap-2`}>
                        <img src={location} className={`${iconsStyle}`} alt="" />
                        <p className={`${textStyle} text-white mb-0`}>
                            {t("location")}  </p>
                    </div>
                </div>
            </div>
        )
    }

    export default HeaderContact
